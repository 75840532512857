<template>
  <v-container fluid>
    <v-card flat class="mx-auto users--top">
      <v-card-title>
        <span>All Users</span>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          class="mx-3"
          dense
          single-line
          hide-details
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          class="elevation-0 users--table"
        >
          <template v-slot:item.email_verified_at="{ item }">
            <v-chip
              label
              :color="
                item.email_verified_at ? 'green lighten-4' : 'red lighten-4'
              "
            >
              <v-icon v-if="item.email_verified_at" color="green"
                >mdi-check</v-icon
              >
              <v-icon v-else color="red">mdi-close</v-icon>
              <span
                :class="
                  item.email_verified_at
                    ? 'green--text text--darken-4'
                    : 'red--text text--darken-4'
                "
                >{{
                  item.email_verified_at ? "Verified" : "Not Verified"
                }}</span
              >
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex">
              <v-btn
                small
                class="mr-2 blue white--text text-capitalize"
                @click="editItem(item)"
              >
                <v-icon small> mdi-pencil </v-icon>
                edit
              </v-btn>
              <v-btn
                small
                class="mr-2 error text-capitalize"
                @click="deleteItem(item)"
              >
                <v-icon small> mdi-pencil </v-icon>
                delete
              </v-btn>
              <v-btn
                small
                class="mr-2 indigo white--text text-capitalize"
                @click="sendPasswordReset(item)"
              >
                <v-icon small> mdi-send-circle-outline </v-icon>
                Send Password Reset Link
              </v-btn>
              <v-btn
                class="primary text-capitalize"
                small
                v-if="!item.is_admin && !item.is_approver"
                @click="loginAs(item)"
              >
                <v-icon small> mdi-login-variant </v-icon>
                login as profile {{ item.profile_id }}
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <AdminUserDialog :dialog="dialog" :user="user" @close="closeDialog" />
  </v-container>
</template>
<script>
import AdminUserDialog from "../components/AdminUserDialog.vue";
export default {
  name: "AdminUsers",
  components: {
    AdminUserDialog,
  },
  data() {
    return {
      dialog: false,
      user: {},
      search: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Admin/getUsers");
    });
  },
  computed: {
    users() {
      return this.$store.getters["Admin/adminGetters"]("users");
    },
    filteredItems() {
      if (!this.search) return this.users; // Return all items if no search term
      const search = this.search.toLowerCase();
      const searchWords = search.split(" ");
      return this.users.filter((item) => {
        // Filter items based on name matching search terms in both directions
        const nameWords = item.name.toLowerCase();
        const email = item.email.toLowerCase();
        const profileId = item.profile_id?.toLowerCase();
        return (
          searchWords.some((word) => nameWords.includes(word)) ||
          searchWords.some((word) => email.includes(word)) ||
          item.id_number.toLowerCase().includes(search) ||
          item.id_number.toLowerCase().includes(search) ||
          item.phone_no?.includes(search) ||
          searchWords.some((word) => profileId?.includes(word))
        );
      });
    },
    settings() {
      return this.$store.getters["Admin/adminGetters"]("settings");
    },
    headers() {
      return [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "email Verified",
          value: "email_verified_at",
        },
        {
          text: "Profile Id",
          value: "profile_id",
        },
        {
          text: "ID Number",
          value: "id_number",
        },
        {
          text: "phone number",
          value: "phone_no",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    editItem(item) {
      this.dialog = true;
      this.user = item;
    },

    deleteItem(item) {
      this.$store.dispatch("Admin/deleteUser", item);
    },
    closeDialog() {
      this.dialog = false;
      this.user = {};
    },
    sendPasswordReset(item) {
      this.$confirm.show({
        title: "Send Password Reset Link?",
        text: "Are you sure you want to send a password reset link to this user?",
        onConfirm: () => {
          this.$store.dispatch("Admin/sendPasswordReset", item);
        },
      });
    },
    loginAs(item) {
      this.$confirm.show({
        title: `Login As ${item.profile_id}`,
        text: "Are you sure you want to login as this user?",
        onConfirm: () => {
          this.$store.dispatch("Admin/loginAs", item);
        },
      });
    },

    customFilter(items, search, filter) {
      search = search.toString().toLowerCase();
      return items.filter((i) =>
        Object.keys(i).some((j) => filter(i[j], search))
      );
    },
  },
};
</script>
<style>
/**.users--table {
    height: 60vh;
    overflow-y: scroll;
  }*/
.users--top > .v-card__title {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}
</style>
